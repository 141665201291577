
window.addEventListener("DOMContentLoaded", () => {

	declare_event_on_filter();

	declare_event_on_loadmore();

	declare_swiper();

});

function declare_event_on_filter(container){
	
	if(container){
		const filter_category = container.querySelectorAll('.filters_category')[0];
		
		let is_slider=false;
		if(filter_category.classList.contains('slider')){
			is_slider=true;
		}

		let filters=filter_category.querySelectorAll('a');

		filters.forEach((filter) => {

			let cat_id=filter.getAttribute("data-id");

			filter.addEventListener('click', function () {
				if(filter.classList.contains('actif')){
					filter_by_cat("", container, is_slider);
					filter.classList.remove('actif')
				}else{
					filter_by_cat(cat_id, container, is_slider);

					filters.forEach((filter) => {
						filter.classList.remove('actif');
					})
					filter.classList.add('actif');
				}
			})
		});

	}else{
		
		const filters_category = document.querySelectorAll('.wp-block-latest-posts .filters_category');
		if(filters_category.length!=0){
				let is_slider=false;
				if(filters_category[0].classList.contains('slider')){
					is_slider=true;
				}

				
				let filters=filters_category[0].querySelectorAll('a');

				filters.forEach((filter) => {

					let cat_id=filter.getAttribute("data-id");

					filter.addEventListener('click', function () {

						const container = filters_category[0].closest('.wp-block-latest-posts');
						if(filter.classList.contains('actif')){
							filter_by_cat("", container, is_slider);
							filter.classList.remove('actif')
						}else{
							filter_by_cat(cat_id, container, is_slider);

							filters.forEach((filter) => {
								filter.classList.remove('actif');
							})
							filter.classList.add('actif');
						}
					})
				});
			// }
		}
	}
}

function filter_by_cat(cat_id, container, is_slider){

	if(is_slider){
		let posts_per_page=container.getAttribute('data-post_per_page');
		let excerpt_length=container.getAttribute('data-excerpt_length');
		let order=container.getAttribute('data-order');
		let orderby=container.getAttribute('data-orderby');
		let type=container.getAttribute('data-type');

		container.setAttribute('data-cat', cat_id);

		container.style.opacity="0.6";
		
		let _data = {
			action: 'filter_cat_posts',
			posts_per_page: posts_per_page,
			excerpt_length: excerpt_length,
			cat: cat_id,
			order: order,
			orderby: orderby,
			type: type,
		};

		fetch(ajaxurl['ajax_url'], {
			method: 'POST',
			body: (new URLSearchParams(_data)).toString(),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded'
			}
		}).then(function (response) {
			
			if (response.ok) {
				response.text().then(function (response) {
					// container.innerHTML = response;
					
					container.querySelectorAll('.swiper .swiper-wrapper')[0].innerHTML= response;

					container.style.opacity="1";

					declare_swiper();


					//On remet le offset par défaut
					let posts_per_page=container.getAttribute('data-post_per_page');
					container.setAttribute('data-offset',posts_per_page);
					
				});
			} else {
				container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
			}
		})

	}else{
		let posts_per_page=container.getAttribute('data-post_per_page');
		let excerpt_length=container.getAttribute('data-excerpt_length');
		let order=container.getAttribute('data-order');
		let orderby=container.getAttribute('data-orderby');
		let type=container.getAttribute('data-type');
		//Pagination ou loadmore ou rien
		let loading=container.getAttribute('data-loading');

		let clean_url=window.location.href.replace(/\/page.*$/, "/");

		container.setAttribute('data-cat', cat_id);

		container.style.opacity="0.6";
		
		let _data = {
			action: 'filter_cat_posts',
			posts_per_page: posts_per_page,
			url: clean_url,
			excerpt_length: excerpt_length,
			cat: cat_id,
			loading: loading,
			order: order,
			orderby: orderby,
			type: type,
		};

		fetch(ajaxurl['ajax_url'], {
			method: 'POST',
			body: (new URLSearchParams(_data)).toString(),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded'
			}
		}).then(function (response) {
			
			if (response.ok) {
				response.text().then(function (response) {

					container.innerHTML = response;
					declare_event_on_filter(container);
					declare_event_on_loadmore(container);
					container.style.opacity="1";

					//On remet le offset par défaut
					let posts_per_page=container.getAttribute('data-post_per_page');
					container.setAttribute('data-offset',posts_per_page);
					
				});
			} else {
				container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
			}
		})
	}

}

function declare_event_on_loadmore(container){


	const loadmores = document.querySelectorAll('.wp-block-latest-posts .loadmore');

	if(loadmores.length!=0){
		if(loadmores.length>1){

			loadmores.forEach((loadmore) => {

				const container = loadmore.closest('.wp-block-latest-posts');

				loadmore.addEventListener('click', function () {
					loadmore_posts(loadmore, container);
				})
			});
		}else{
			const container = loadmores[0].closest('.wp-block-latest-posts');

			loadmores[0].addEventListener('click', function () {
				loadmore_posts(loadmores[0], container);
			})
		}
	}
}


function loadmore_posts(loadmore, container){

	let posts_per_page=container.getAttribute('data-post_per_page');
	let offset=container.getAttribute('data-offset');
	let order=container.getAttribute('data-order');
	let orderby=container.getAttribute('data-orderby');
	let is_main_blog=container.getAttribute('data-blog');
	let is_sticky=container.getAttribute('data-sticky');
	let cat="";
	let post_count="";

	if(container.querySelectorAll('.filters_category')[0]){
		cat=container.querySelectorAll('.filters_category')[0].getAttribute('data-cat');
	}

	if(cat){
		post_count=container.querySelectorAll('.filters_category')[0].getAttribute('data-post_count');
	}else{
		post_count=container.getAttribute('data-post_count');
	}

	container.querySelectorAll('li.loadmore');
	container.style.opacity="0.6";

	let _data = {
        action: 'loadmore_posts',
        posts_per_page: posts_per_page,
        offset: offset,
        order: order,
        orderby: orderby,
        cat: cat,
		is_main_blog: is_main_blog,
		is_sticky: is_sticky,
    };

    fetch(ajaxurl['ajax_url'], {
        method: 'POST',
        body: (new URLSearchParams(_data)).toString(),
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
		
		if (response.ok) {
			
			response.text().then(function (response) {
				loadmore.insertAdjacentHTML('beforebegin', response);
				container.style.opacity="1";	

				let new_offset=parseInt(offset) + parseInt(posts_per_page);
				container.setAttribute("data-offset", new_offset);
				
				if (parseInt(new_offset) >= parseInt(post_count)) {
					loadmore.style.display = "none";
				}
				
			});
		} else {
			container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
		}
	})

}

function declare_swiper(){
    let latest_posts= document.querySelectorAll('.wp-block-latest-posts');
    
    if(latest_posts.length!=0){
      if(latest_posts.length>1){
        
        latest_posts.forEach((item) => {
			let swiper_item="";
			if(item.querySelectorAll('.swiper').length!=0){
				swiper_item=item.querySelectorAll('.swiper')[0];

				let swiper_name=".swiper_"+swiper_item.getAttribute('data-id');

				let nb_slide=parseInt(swiper_item.getAttribute('data-nb-slide'));
				let autoSlide=(/true/).test(swiper_item.getAttribute('data-auto-slide'));

				let breakpoints={
					0: {
						slidesPerView: 1,
					},
					577: {
						slidesPerView: 2,
					},
					767: {
						slidesPerView: 3,
					},
					992: {
						slidesPerView: nb_slide,
					},
				};

				if(nb_slide==1){
					breakpoints={
						0: {
							slidesPerView: nb_slide,
						},
					};	
				}
				
				const swiper = new Swiper(swiper_name, {
					watchSlidesProgress: true,
					spaceBetween: 30,
					autoplay: {
						delay: 3000,
						disableOnInteraction: true,
						pauseOnMouseEnter: true,
					},
					autoplay: autoSlide,
					breakpoints: breakpoints,
					navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
					},
					pagination: {
					el: ".swiper-pagination",
					},
				});
			}
        });
      }else{

		if(latest_posts[0].querySelectorAll('.swiper').length!=0){
			let item=latest_posts[0].querySelectorAll('.swiper')[0];
			let swiper_name=".swiper_"+item.getAttribute('data-id');

			let nb_slide=item.getAttribute('data-nb-slide');
			let autoSlide=(/true/).test(item.getAttribute('data-auto-slide'));

			let breakpoints={
				0: {
					slidesPerView: 1,
				},
				577: {
					slidesPerView: 2,
				},
				767: {
					slidesPerView: 3,
				},
				992: {
					slidesPerView: nb_slide,
				},
			};

			if(nb_slide==1){
				breakpoints={
					0: {
						slidesPerView: nb_slide,
					},
				};	
			}

			const swiper = new Swiper(swiper_name, {
				watchSlidesProgress: true,
				spaceBetween: 30,
				autoplay: autoSlide,
				autoplay: {
					delay: 3000,
					disableOnInteraction: true,
					pauseOnMouseEnter: true,
				},
				breakpoints: breakpoints,
				navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
				},
				pagination: {
				el: ".swiper-pagination",
				},
			});
		}
      }
    }
}
